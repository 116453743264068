import React from 'react';
import { FaLightbulb, FaMoon } from 'react-icons/fa';


const ColorSwitcher = () => {

    const switchColor = () => {
        document.querySelector('body').classList.toggle("active-dark-mode");
    }

    return (
        <div className="">

        </div>
    )
}

export default ColorSwitcher;