import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {
    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <div className="section-title">
                                        <h5 className="title">
                                            Last updated: December 17, 2023
                                        </h5>
                                    </div>
                                    <h4>Privacy Policy</h4>
                                    <p>
                                        Last updated: December 17, 2023. This Privacy Policy
                                        describes Our policies and procedures on the collection,
                                        use, and disclosure of Your information when You use the
                                        Service and tells You about Your privacy rights and how
                                        the law protects You. We use Your Personal data to
                                        provide and improve the Service. By using the Service,
                                        You agree to the collection and use of information in
                                        accordance with this Privacy Policy. This Privacy Policy
                                        has been created with the help of the Privacy Policy
                                        Generator.
                                    </p>
                                    <h4>Interpretation and Definitions</h4>
                                    <p>
                                        The words of which the initial letter is capitalized have
                                        meanings defined under the following conditions. The
                                        following definitions shall have the same meaning
                                        regardless of whether they appear in singular or in plural.
                                    </p>
                                    <h4>Definitions</h4>
                                    <ul>
                                        <li>
                                            <strong>Account</strong> means a unique account created
                                            for You to access our Service or parts of our Service.
                                        </li>
                                        <li>
                                            <strong>Affiliate</strong> means an entity that controls,
                                            is controlled by or is under common control with a party,
                                            where "control" means ownership of 50% or more of the
                                            shares, equity interest or other securities entitled to
                                            vote for election of directors or other managing
                                            authority.
                                        </li>
                                        <li>
                                            <strong>Company</strong> (referred to as either "the
                                            Company", "We", "Us" or "Our" in this Agreement) refers to
                                            PalTech LTD, Plovdiv, Bulgaria.
                                        </li>
                                        <li>
                                            <strong>Cookies</strong> are small files that are placed
                                            on Your computer, mobile device, or any other device by a
                                            website, containing the details of Your browsing history
                                            on that website among its many uses.
                                        </li>
                                        {/* ... (Continue modifying the rest of the content) ... */}
                                    </ul>
                                    {/* ... (Continue modifying the rest of the content) ... */}
                                    <h4>Delete Your Personal Data</h4>
                                    <p>
                                        You have the right to delete or request that We assist in
                                        deleting the Personal Data that We have collected about
                                        You. Our Service may give You the ability to delete
                                        certain information about You from within the Service.
                                    </p>
                                    <p>
                                        You may update, amend, or delete Your information at any
                                        time by signing in to Your Account, if you have one, and
                                        visiting the account settings section that allows you to
                                        manage Your personal information. You may also contact Us
                                        to request access to, correct, or delete any personal
                                        information that You have provided to Us.
                                    </p>
                                    <p>
                                        Please note, however, that We may need to retain certain
                                        information when we have a legal obligation or lawful
                                        basis to do so.
                                    </p>
                                    <h4>Disclosure of Your Personal Data</h4>
                                    <h5>Business Transactions</h5>
                                    <p>
                                        If the Company is involved in a merger, acquisition or
                                        asset sale, Your Personal Data may be transferred. We will
                                        provide notice before Your Personal Data is transferred
                                        and becomes subject to a different Privacy Policy.
                                    </p>
                                    <h5>Law enforcement</h5>
                                    <p>
                                        Under certain circumstances, the Company may be required
                                        to disclose Your Personal Data if required to do so by law
                                        or in response to valid requests by public authorities
                                        (e.g. a court or a government agency).
                                    </p>
                                    <h5>Other legal requirements</h5>
                                    <p>
                                        The Company may disclose Your Personal Data in the good
                                        faith belief that such action is necessary to:
                                    </p>
                                    <ul>
                                        <li>Comply with a legal obligation</li>
                                        <li>
                                            Protect and defend the rights or property of the Company
                                        </li>
                                        <li>
                                            Prevent or investigate possible wrongdoing in connection
                                            with the Service
                                        </li>
                                        <li>
                                            Protect the personal safety of Users of the Service or
                                            the public
                                        </li>
                                        <li>Protect against legal liability</li>
                                    </ul>
                                    <h4>Security of Your Personal Data</h4>
                                    <p>
                                        The security of Your Personal Data is important to Us, but
                                        remember that no method of transmission over the Internet,
                                        or method of electronic storage is 100% secure. While We
                                        strive to use commercially acceptable means to protect
                                        Your Personal Data, We cannot guarantee its absolute
                                        security.
                                    </p>
                                    <h4>Children's Privacy</h4>
                                    <p>
                                        Our Service does not address anyone under the age of 13.
                                        We do not knowingly collect personally identifiable
                                        information from anyone under the age of 13. If You are a
                                        parent or guardian and You are aware that Your child has
                                        provided Us with Personal Data, please contact Us. If We
                                        become aware that We have collected Personal Data from
                                        anyone under the age of 13 without verification of
                                        parental consent, We take steps to remove that information
                                        from Our servers.
                                    </p>
                                    <p>
                                        If We need to rely on consent as a legal basis for
                                        processing Your information and Your country requires
                                        consent from a parent, We may require Your parent's
                                        consent before We collect and use that information.
                                    </p>
                                    <h4>Links to Other Websites</h4>
                                    <p>
                                        Our Service may contain links to other websites that are
                                        not operated by Us. If You click on a third party link,
                                        You will be directed to that third party's site. We
                                        strongly advise You to review the Privacy Policy of every
                                        site You visit.
                                    </p>
                                    <p>
                                        We have no control over and assume no responsibility for
                                        the content, privacy policies or practices of any third
                                        party sites or services.
                                    </p>
                                    <h4>Changes to this Privacy Policy</h4>
                                    <p>
                                        We may update Our Privacy Policy from time to time. We
                                        will notify You of any changes by posting the new Privacy
                                        Policy on this page.
                                    </p>
                                    <p>
                                        We will let You know via email and/or a prominent notice
                                        on Our Service, prior to the change becoming effective and
                                        update the "Last updated" date at the top of this Privacy
                                        Policy.
                                    </p>
                                    <p>
                                        You are advised to review this Privacy Policy periodically
                                        for any changes. Changes to this Privacy Policy are
                                        effective when they are posted on this page.
                                    </p>
                                    <h4>Contact Us</h4>
                                    <ul>
                                        <li>
                                            By email:{' '}
                                            <a href="mailto:contact@paltechstudio.com">
                                                contact@paltechstudio.com
                                            </a>
                                        </li>
                                        <li>By phone number: +359 895392547</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    );
};

export default PrivacyPolicy;