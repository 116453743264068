import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceProp from '../component/service/ServiceProp';
import ServiceData from "../data/service/ServiceMain.json";
import { slugify } from '../utils';
import ServicePropOne from "../component/service/ServicePropOne";

const allData = ServiceData;


const ServiceOne = () => {

    const designData = allData.filter(data => slugify(data.cate ? data.cate : "") === "design");
    const developmentData = allData.filter(data => slugify(data.cate ? data.cate : "") === "development");
    const marketingData = allData.filter(data => slugify(data.cate ? data.cate : "") === "online-marketing");
    const businessData = allData.filter(data => slugify(data.cate ? data.cate : "") === "business");
    const technologyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "technology");
    const strategyData = allData.filter(data => slugify(data.cate ? data.cate : "") === "content-strategy");



    return (
        <>
        <SEO title="Services" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            {/*<BcrumbBannerOne*/}
            {/*    title="Best solutions for your business"*/}
            {/*    paragraph ="Give your business a unique logo to stand out from crowd. We’ll create logo specifically for your company.*/}
            {/*    "*/}
            {/*    styleClass=""*/}
            {/*    mainThumb="/images/banner/banner-thumb-4.png"*/}
            {/*/>*/}
            <div className="service-scroll-navigation-area pt--250 pb--70">

                {/* Service Nav */}

                <div className="row">
                    <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="6" marginTop="yes"/>
                </div>
            </div>
            <CtaLayoutOne />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ServiceOne;