import React from 'react';
import Slider from "react-slick";
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerOne from '../component/banner/BannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProjectGridFive from "./ProjectGridFive";
import CounterUpOne from "../component/counterup/CounterUpOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import TestimonialItem from "../component/testimonial/TestimonialPropOne";


const DigitalAgency = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true
      };
    return (
        <>
        <SEO title="Game Dev Agency"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            {/*<BannerOne />*/}
            {<BannerOne />}
            <div className="section section-padding">
                <div className="container">
                    <SectionTitle
                        subtitle="What We Can Do For You"
                        title="Services we can <br> help you with"
                        description=""
                        textAlignment="heading-left mb--20 mb_md--70"
                        textColor=""
                    />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="6" marginTop="yes"/>
                    </div>
                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>

            </div>
            {/* github readme https://github.com/akiran/react-slick
            slick properties https://react-slick.neostack.com/docs/api/
            note style is loaded via css from cdn added at src\common\SEO.js  */}
            {/*<div className='carousel-wrapper'>*/}
            {/*    <Slider {...settings}>*/}
            {/*        <div>*/}
            {/*            <h3>1</h3>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h3>2</h3>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h3>3</h3>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h3>4</h3>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h3>5</h3>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <h3>6</h3>*/}
            {/*        </div>    */}
            {/*    </Slider>*/}
            {/*</div>*/}
            
            {/*<AboutOne />*/}
            <ProjectGridFive />
            {<CounterUpOne />}
            <TestimonialOne />
            {/*<div className="section bg-color-light section-padding">*/}
            {/*    <div className="container">*/}
            {/*        <SectionTitle */}
            {/*            subtitle="Pricing Plan"*/}
            {/*            title="We’ve built solutions for..."*/}
            {/*            description="Flexible pricing options for freelancers*/}
            {/*            and design teams."*/}
            {/*            textAlignment=""*/}
            {/*            textColor=""*/}
            {/*        />*/}
            {/*        <PricingOne />*/}
            {/*    </div>*/}
            {/*    <ul className="list-unstyled shape-group-3">*/}
            {/*        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="shape" /></li>*/}
            {/*        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"} alt="shape" /></li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
            {/*<BrandOne />*/}
            {<CtaLayoutOne />}
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default DigitalAgency;

