import React from 'react';
import { Link } from 'react-router-dom';
import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from '../../utils';


const AllData = ServiceData;

const ServicePropOne = ({colSize, serviceStyle, itemShow}) => {

    return (
		<>
			{AllData.slice(0, itemShow).map((data, index) => (
				<div className={`${colSize}`} key={index} >
					<div className={`services-grid ${serviceStyle}`}>
						<div className="thumbnail">
							<img src={process.env.PUBLIC_URL + data.image} alt="icon" />
						</div>
						<div className="content">
							<h5 className="title">{data.title}</h5>
							<p>{data.description}</p>
						</div>
					</div>
			 	</div>
			))}
		</>
    )
}

export default ServicePropOne;