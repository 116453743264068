import React from 'react';
import {
    FaFacebookF,
    FaLinkedin,
    FaInstagram,
} from "react-icons/fa";


import ServiceData from "../../data/service/ServiceMain.json";
import {Link} from "react-router-dom";

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {


    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link" style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <ul className="list-unstyled">
                            <li><a href="https://www.facebook.com/PalTech98" target="_blank"><FaFacebookF /></a></li>
                            <li><a href="https://www.linkedin.com/company/paltech-studio" target="_blank"><FaLinkedin /></a></li>
                            <li><a href="https://www.instagram.com/paltech_studio/" target="_blank"><FaInstagram /></a></li>
                            <li><a href="https://www.upwork.com/o/companies/~01a6efd2e209e4090c/" target="_blank"><img src="/images/UpworkIcon.jpg" /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="">
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by PalTech Ltd.</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/terms-use"}>Terms of Use</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;