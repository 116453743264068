import React from 'react';
import Tilty from 'react-tilty';

const BcrumbBannerOne = ({ title, paragraph, styleClass, mainThumb, links }) => {
    return (
        <div className="custom-mobile-behaviour breadcrum-area breadcrumb-banner">
            <div className="container">
                <div className="section-heading heading-left">
                    <h1 className="title h2" dangerouslySetInnerHTML={{ __html: title }}></h1>
                    <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
                </div>
                <div className={`banner-thumbnail ${styleClass}`}>
                    <Tilty perspective={2000} reset={false}>
                        <img src={process.env.PUBLIC_URL + mainThumb} alt="Illustration" />
                    </Tilty>
                </div>
            </div>
            <ul className="custom-mobile-behaviour shape-group-8 list-unstyled">
                {links && links.map((link, index) => (
                    <li key={index} className={`shape shape-${index + 1}`}>
                        <a href={link.url} target="_blank" rel="noopener noreferrer">
                            {link.name}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default BcrumbBannerOne;