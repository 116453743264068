import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';


const BannerOne = () => {
    return (
        <div className="banner banner-style-1">
            <div className="container">
                <div className="row align-items-end align-items-xl-start">
                    <div className="col-lg-6">
                        <div className="banner-content margin-bottom-0">
                            <AnimationOnScroll animateIn="fadeInUp" animateOnce={true} delay={100}>
                                <h1 className="heading-level-1">Take Your Game Idea To The Next Level</h1>
                                <span className="subtitle" style={{ fontSize: "24px", textAlign: 'left' }}>Transforming Your Game Idea into a Seamless and Bug-Free Gaming Experience</span>
                                <div className="button-container">
                                    <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-large" style={{ marginBottom: "15px"}}>Discuss Your Project</Link>
                                    <Link to={process.env.PUBLIC_URL + "/project-width-one"} className="axil-btn btn-large btn-fill-white view-showcase" style={{ marginBottom: "10px"}}>View Showcase</Link>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-thumbnail">
                            <AnimationOnScroll animateIn="zoomIn" duration={1} delay={100} animateOnce={true}>
                                <div className="large-thumb">
                                    <img
                                        src={process.env.PUBLIC_URL + "/images/PortfolioThumbnails/HeroImage.webp"}
                                        style={{
                                            height: "auto",
                                        }}
                                        alt="Hero Image"
                                    />
                                </div>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}>
                            <div className="large-thumb-2">
                            </div>
                            </AnimationOnScroll>
                            <ul className="list-unstyled shape-group">
                                <li className="shape shape-1">
                                    <AnimationOnScroll animateIn="slideInLeft" duration={1} delay={800} animateOnce={true}>
                                    </AnimationOnScroll>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="list-unstyled shape-group-21">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"} alt="Bubble" />
                </li>
            </ul>
        </div>
    )
}

export default BannerOne;