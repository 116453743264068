import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title="Terms Of Use"
                    page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                    <p>Welcome to PalTech Studio!</p>
                                    <p>These terms and conditions outline the rules and regulations for the use of PalTech's Website, located at paltechstudio.com. By accessing this website, we assume you accept these terms and conditions. Do not continue to use paltechstudio.com if you do not agree to take all of the terms and conditions stated on this page.</p>

                                    <h4>1. Cookies</h4>
                                    <p>We employ the use of cookies. By accessing paltechstudio.com, you agree to use cookies in agreement with PalTech's Privacy Policy. Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

                                    <h4>2. License</h4>
                                    <p>Unless otherwise stated, PalTech and/or its licensors own the intellectual property rights for all material on paltechstudio.com. All intellectual property rights are reserved. You may access this from paltechstudio.com for your own personal use subjected to restrictions set in these terms and conditions.</p>
                                    <p>You must not:
                                        <ul>
                                            <li>Republish material from paltechstudio.com</li>
                                            <li>Sell, rent or sub-license material from paltechstudio.com</li>
                                            <li>Reproduce, duplicate or copy material from paltechstudio.com</li>
                                            <li>Redistribute content from paltechstudio.com</li>
                                        </ul>
                                    </p>
                                    <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Free Terms and Conditions Generator.</p>

                                    <h4>3. User Comments</h4>
                                    <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. PalTech does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of PalTech, its agents and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions.</p>
                                    <p>To the extent permitted by applicable laws, PalTech shall not be liable for the Comments or for any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
                                    <p>PalTech reserves the right to monitor all Comments and to remove any Comments that can be considered inappropriate, offensive, or cause a breach of these Terms and Conditions.</p>

                                    {/* Continue with the rest of your content */}
                                    {/* ... */}

                                    <h4>8. Content Liability</h4>
                                    <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of any third-party rights.</p>

                                    <h4>9. Reservation of Rights</h4>
                                    <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

                                    <h4>10. Removal of links from our website</h4>
                                    <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to do so or to respond to you directly. We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

                                    <h4>11. Disclaimer</h4>
                                    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
                                        <ul>
                                            <li>limit or exclude our or your liability for death or personal injury;</li>
                                            <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                                            <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                                            <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                                        </ul>
                                    </p>
                                    <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;