import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import Alert from 'react-bootstrap/Alert';

const Result = ({ variant, message }) => {
    return <Alert variant={variant} className="success-msg">{message}</Alert>;
};

const FormThree = () => {
    const form = useRef();
    const recaptchaRef = useRef();
    const [result, showResult] = useState({ success: false, error: false });

    const sendEmail = (e) => {
        e.preventDefault();

        // Verify reCAPTCHA
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            alert("Please verify that you're not a robot.");
            return;
        }

        emailjs.sendForm('service_7q6rqmk', 'template_0bda36k', form.current, 'o3zqI8zzSXGRFM4Fc')
            .then(() => {
                form.current.reset();
                showResult({ success: true, error: false });

                setTimeout(() => {
                    showResult({ success: false, error: false });
                }, 5000);
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                showResult({ success: false, error: true });
            });
    };


    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
            <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" name="from_name" placeholder="John Smith" required />
            </div>
            <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control" name="from_email" placeholder="example@mail.com" required />
            </div>
            <div className="form-group mb--40">
                <label>How can we help you?</label>
                <textarea name="message" id="contact-message" className="form-control textarea" cols="30" rows="6"></textarea>
            </div>
            <div className="form-group mb--40">
                {/* Add the reCAPTCHA component */}
                <ReCAPTCHA ref={recaptchaRef} sitekey="6LeJkzUpAAAAAAnseszjtBPqAYxu4MLXmUKDLoEa" />
            </div>
            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary w-auto btn-primary full-width" name="submit-btn">
                    SEND
                </button>
            </div>
            <div className="form-group">
                {result.success && <Result variant="success" message="Your Message has been successfully sent." />}
                {result.error && <Result variant="danger" message="An error occurred while sending the message." />}
            </div>
        </form>
    );
};

export default FormThree;