import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li><Link to={process.env.PUBLIC_URL + "/service-one"}>Services</Link></li>
                <li className="menu-item-has-children">
                    <Link to={process.env.PUBLIC_URL + "/project-width-two"}>Portfolio</Link>
                </li>
                <li className="menu-item-has-children">
                    {/*<Link to={process.env.PUBLIC_URL + "/Testimonials"}>Testimonials</Link>*/}
                </li>
            </ul>
        </nav>
    )
}

export default Nav;