import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SectionTitle from '../elements/section-title/SectionTitle';
import TestimonialPropTwo from '../component/testimonial/TestimonialPropTwo';
import TestimonialData from "../data/testimonial/TestimonialData.json";
import { slugify } from '../utils';
import TestimonialPropOne from "../component/testimonial/TestimonialPropOne";

const allData = TestimonialData;

const Testimonials = () => {

    const facebookData = allData.filter(data => slugify(data.fromtext) === "facebook");
    const googleData = allData.filter(data => slugify(data.fromtext) === "google");
    const yelpData = allData.filter(data => slugify(data.fromtext) === "yelp");

    return (
        <>
        <SEO title="Testimonials" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <div className="section section-padding">
                    <div className="container">
                        <SectionTitle
                            subtitle="Testimonial"
                            title="Here is some feedback <br> from our clients"
                            textAlignment="heading-left"
                            textColor=""
                        />
                        <div className="row">
                            <TestimonialPropOne colSize="col-lg-4" itemShow="3"/>
                        </div>
                    </div>
                    <ul className="shape-group-4 list-unstyled">
                        <li className="shape-1">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" />
                        </li>
                    </ul>
                </div>

                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Testimonials;